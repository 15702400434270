import React from 'react';
import { useQuery } from 'react-apollo';

import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { usePageState } from '@confluence/page-context';
import { generateSlug } from '@confluence/route-manager';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { ContentRedirectComponent } from './ContentRedirectComponent';
import { ContentRedirectQuery } from './ContentRedirectQuery.graphql';
import type {
	ContentRedirectQuery as Q,
	ContentRedirectQueryVariables as V,
} from './__types__/ContentRedirectQuery';

export const ContentRedirect = () => {
	const [{ contentId, contentSlug, spaceKey }] = usePageState();

	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');
	const { data, loading, error } = useQuery<Q, V>(ContentRedirectQuery, {
		errorPolicy: 'all',
		variables: { contentId, includeAlias: isSpaceAliasFFEnabled },
		context: { single: true },
	});

	if (loading) {
		return null;
	}

	// UnauthorizedError is a special case here since no specific UI are required for it.
	// In case of an UnauthorizedError the data or content are expected to be empty, and
	// ContentRedirectComponent will handle such case properly.
	if (isUnauthorizedError(error)) {
		markErrorAsHandled(error);
	}

	const content = data?.content?.nodes?.[0];
	const space = content?.space;
	const title = content?.title;

	const contentWithSlug = content && {
		...content,
		slug: generateSlug(title),
		spaceKey: (isSpaceAliasFFEnabled && space?.alias) || space?.key,
		createdDate: content?.history?.createdDate,
	};

	return (
		<ContentRedirectComponent
			contentSlugInUrl={contentSlug}
			contentWithSlug={contentWithSlug}
			space={space}
			spaceKeyInUrl={spaceKey}
		/>
	);
};

import React from 'react';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { useSessionData } from '@confluence/session-data';

import { getLoginLink } from './getLoginLink';

/**
 * This component is solely responsible for redirecting user. It is made intentionally not check permission.
 * Use it ONLY when it is guarded by a permission check.
 * ```js
 *  <Query
 *   errorPolicy="all"
 *   query={Query}
 *   variables={{ ... }}
 * >
 *   {({ data, error, loading }) => {
 *     // ...
 *     if (isUnauthorizedError(error)) {
 *       return <LoginIfAnonymous />;
 *     }
 *     // ...
 *   }}
 * </Query>
 * ```
 */
export const LoginIfAnonymous = () => {
	const { userId } = useSessionData();

	return userId ? null : (
		<Redirection href={getLoginLink()} usePush={isEmbeddedConfluence_DO_NOT_USE()} />
	);
};

import React, { Fragment, memo } from 'react';

import { DocumentContentTitle } from '@confluence/document-title';
import { LoginIfAnonymous } from '@confluence/login-if-anonymous';
import { RestrictedPage } from '@confluence/no-permission';

import { ContentExpansionContainer } from './styles';
import type { UnauthorizedScreenProps } from './types';

export const UnauthorizedScreen = memo((props: UnauthorizedScreenProps) => (
	<Fragment>
		<LoginIfAnonymous />
		<ContentExpansionContainer>
			<DocumentContentTitle siteTitleOnly />
			<RestrictedPage {...props} />
		</ContentExpansionContainer>
	</Fragment>
));

import gql from 'graphql-tag';

export const ViewPageGatewayQuery = gql`
	query ViewPageGatewayQuery($contentId: ID) {
		content(id: $contentId) {
			nodes {
				id
				status
			}
		}
	}
`;

import type { ApolloError } from 'apollo-client';
import React from 'react';

import { PageViewRestrictions } from './PageViewRestrictions';
import { SpaceRestrictionCheck } from './SpaceRestrictionCheck';

type RestrictionsCheckProps = {
	children: (result: {
		error: ApolloError | undefined;
		hasDirectViewRestrictions: boolean | undefined;
		hasInheritedViewRestrictions: boolean | undefined;
		isSpaceRestricted: boolean | undefined;
		loading: boolean;
	}) => JSX.Element | null;
	contentId: string;
	spaceKey: string;
};

export const RestrictionsCheck = ({ children, spaceKey }: RestrictionsCheckProps) => (
	<SpaceRestrictionCheck asSuperAdmin={false} spaceKey={spaceKey}>
		{({
			error: spaceRestrictionCheckError,
			isSpaceRestricted,
			loading: spaceRestrictionCheckIsLoading,
		}) => (
			<PageViewRestrictions>
				{({
					error: pageViewRestrictionsError,
					hasDirectViewRestrictions,
					hasInheritedViewRestrictions,
					loading: pageViewRestrictionsIsLoading,
				}) =>
					children({
						error: spaceRestrictionCheckError || pageViewRestrictionsError,
						hasDirectViewRestrictions,
						hasInheritedViewRestrictions,
						isSpaceRestricted,
						loading: spaceRestrictionCheckIsLoading || pageViewRestrictionsIsLoading,
					})
				}
			</PageViewRestrictions>
		)}
	</SpaceRestrictionCheck>
);

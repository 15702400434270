import React, { useContext } from 'react';

import { ViewPageGateway } from '@confluence/view-page-gateway';
import { ExperienceTrackerContext, VIEW_PAGE_EXPERIENCE } from '@confluence/experience-tracker';
import { markErrorAsHandled } from '@confluence/graphql';
import { fg } from '@confluence/feature-gating';
import { getStatusCodeError } from '@confluence/error-boundary';

import { type ContentPrerequisitesProps, ContentPrerequisites } from './ContentPrerequisites';

type PagePrerequisitesProps = Pick<
	ContentPrerequisitesProps,
	'children' | 'contentQueryResult' | 'route'
>;

export const PagePrerequisites = ({
	children,
	contentQueryResult,
	route,
}: PagePrerequisitesProps) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const shouldFailExperienceOnError = fg('confluence_frontend_view_page_experience_errors');

	const handleUnexpectedError = (error: Error) => {
		const isUserError = getStatusCodeError(error)?.startsWith('4');
		if (isUserError) {
			experienceTracker.abort({
				name: VIEW_PAGE_EXPERIENCE,
				reason: error.message,
			});
		} else {
			experienceTracker.fail({
				name: VIEW_PAGE_EXPERIENCE,
				error,
			});
		}
		markErrorAsHandled(error);
		throw error; // Throw to propagate to error boundary - otherwise just shows a blank screen
	};
	// When unauthorized and viewing a restricted page, user is prompted
	// with SuperAdmin gateway
	return (
		<ViewPageGateway>
			{/* @ts-expect-error - Type 'Element' is not assignable to type 'NonNullable<ReactNodeLike>'. This error was introduced after upgrading to TypeScript 5 */}
			<ContentPrerequisites
				route={route}
				contentQueryResult={contentQueryResult}
				handleUnexpectedError={shouldFailExperienceOnError ? handleUnexpectedError : undefined}
			>
				{children}
			</ContentPrerequisites>
		</ViewPageGateway>
	);
};

import gql from 'graphql-tag';

export const ContentRedirectQuery = gql`
	query ContentRedirectQuery($contentId: ID, $includeAlias: Boolean = false) {
		content(id: $contentId) {
			nodes {
				id
				title
				space {
					id
					homepageId
					key
					alias @include(if: $includeAlias)
				}
				history {
					createdDate
				}
			}
		}
	}
`;

import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { Query } from 'react-apollo';

import { PageLoadEnd } from '@confluence/browser-metrics';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { RestrictedPageConfirmation, INACCESSIBLE_PAGE_LOAD } from '@confluence/no-permission';
import { PageSubscriber } from '@confluence/page-context';
import { RestrictionsCheck } from '@confluence/restrictions/entry-points/RestrictionsCheck';
import { RestrictionFrom } from '@confluence/restrictions/entry-points/RestrictionFrom';
import { confluenceLocalStorageInstance, keys } from '@confluence/storage-manager';
import { AdminKeyQuery } from '@confluence/super-admin';

import { ViewPageGatewayQuery } from './ViewPageGatewayQuery.graphql.js';

export function computeRestrictionFromType(
	hasDirectRestrictions,
	hasPageInheritedRestrictions,
	hasSpaceRestrictions,
) {
	// multiple restrictions = at least two.
	const hasMultipleRestrictions = hasDirectRestrictions
		? hasPageInheritedRestrictions || hasSpaceRestrictions
		: hasPageInheritedRestrictions && hasSpaceRestrictions;
	if (hasMultipleRestrictions) {
		return RestrictionFrom.MULTIPLE;
	}

	if (hasDirectRestrictions) {
		return RestrictionFrom.DIRECT;
	}
	if (hasPageInheritedRestrictions) {
		return RestrictionFrom.PAGE_INHERITED;
	}
	if (hasSpaceRestrictions) {
		return RestrictionFrom.SPACE;
	}

	return RestrictionFrom.NONE;
}

const CUSTOM_ATTRIBUTES = {
	inaccessiblePageType: 'RESTRICTED_CONFIRMATION_PAGE',
};

export class ViewPageGatewayComponent extends PureComponent {
	static propTypes = {
		contentId: PropTypes.string,
		spaceKey: PropTypes.string.isRequired,
		children: PropTypes.node.isRequired,
	};

	state = {
		restrictedPageViewConfirmed: false,
	};

	componentDidMount() {
		this.checkConsentToViewPage();
	}

	componentDidUpdate() {
		this.checkConsentToViewPage();
	}

	checkConsentToViewPage() {
		const localStorageValue = confluenceLocalStorageInstance.getItem(
			keys.RESTRICTED_PAGE_CONFIRMED,
		);
		if (!localStorageValue) {
			this.setState({
				restrictedPageViewConfirmed: false,
			});
			return;
		}
		// Set the state if it matches so we can skip rendering the confirmation.
		if (String(localStorageValue) === this.props.contentId) {
			this.setState({
				restrictedPageViewConfirmed: true,
			});
		} else {
			// This means the user is on a different page currently. Can safely
			// delete the entry now so we can render the confirmation again if the
			// former page is accessed again.
			confluenceLocalStorageInstance.removeItem(keys.RESTRICTED_PAGE_CONFIRMED);
			this.setState({
				restrictedPageViewConfirmed: false,
			});
		}
	}

	onRestrictedPageViewConfirmClick = () => {
		confluenceLocalStorageInstance.setItem(keys.RESTRICTED_PAGE_CONFIRMED, this.props.contentId);
		// Set the state regardles of localstorage setItem succeeding or not so we
		// can render the page.
		this.setState({
			restrictedPageViewConfirmed: true,
		});
	};

	renderRestrictedPageConfirmation = () => {
		const { contentId, spaceKey } = this.props;

		return (
			<RestrictionsCheck contentId={contentId} spaceKey={spaceKey}>
				{({
					hasDirectViewRestrictions,
					hasInheritedViewRestrictions,
					isSpaceRestricted,
					loading,
					error,
				}) => {
					if (loading) {
						return null;
					}
					if (error) {
						throw error;
					}

					const restrictionFromType = computeRestrictionFromType(
						hasDirectViewRestrictions,
						hasInheritedViewRestrictions,
						isSpaceRestricted,
					);

					return (
						<Fragment>
							<RestrictedPageConfirmation
								restrictionFromType={restrictionFromType}
								onViewPageClick={this.onRestrictedPageViewConfirmClick}
							/>
							<PageLoadEnd
								metric={INACCESSIBLE_PAGE_LOAD}
								customData={{ contentId, ...CUSTOM_ATTRIBUTES }}
							/>
						</Fragment>
					);
				}}
			</RestrictionsCheck>
		);
	};

	render() {
		const { children, contentId } = this.props;

		if (!contentId) {
			// in this case children renders ContentPrerequisites which shows the appropriate 404 page
			return children;
		}

		const { restrictedPageViewConfirmed } = this.state;
		if (restrictedPageViewConfirmed) {
			return children;
		}

		return (
			<AdminKeyQuery>
				{({ loading, error, isSuperAdmin }) => {
					if (loading) {
						return null;
					}
					if (error) {
						throw error;
					}
					if (!isSuperAdmin) {
						return children;
					}

					return (
						<Query
							query={ViewPageGatewayQuery}
							variables={{ contentId }}
							fetchPolicy="network-only"
							context={{ asSuperAdmin: false }}
						>
							{({ loading, error }) => {
								if (loading) {
									return null;
								}
								// render confirmation if the user is accessing a restricted
								// page and has not already confirmed viewing the page
								if (error && !restrictedPageViewConfirmed && isUnauthorizedError(error)) {
									return this.renderRestrictedPageConfirmation();
								}

								return children;
							}}
						</Query>
					);
				}}
			</AdminKeyQuery>
		);
	}
}

export const ViewPageGateway = ({ children }) => (
	<PageSubscriber>
		{({ contentId, spaceKey }) => (
			<ViewPageGatewayComponent contentId={contentId} spaceKey={spaceKey}>
				{children}
			</ViewPageGatewayComponent>
		)}
	</PageSubscriber>
);

ViewPageGateway.propTypes = {
	children: PropTypes.node.isRequired,
};

import type { ApolloError } from 'apollo-client';

import { usePageRestrictionsContext } from '@confluence/page-restrictions-context';

type PageViewRestrictionsProps = {
	children: (result: {
		error: ApolloError | undefined;
		hasDirectViewRestrictions: boolean | undefined;
		hasInheritedViewRestrictions: boolean | undefined;
		loading: boolean;
	}) => JSX.Element | null;
};

export function PageViewRestrictions({ children }: PageViewRestrictionsProps) {
	const { restrictions, error, loading } = usePageRestrictionsContext();

	let hasDirectViewRestrictions: boolean | undefined;
	let hasInheritedViewRestrictions: boolean | undefined;

	if (!loading) {
		hasDirectViewRestrictions = Boolean(restrictions?.hasViewRestrictions);
		hasInheritedViewRestrictions = Boolean(restrictions?.hasInheritedRestrictions);
	}

	return children({
		error,
		hasDirectViewRestrictions,
		hasInheritedViewRestrictions,
		loading,
	});
}
